/*

 Custom colors for Telesil organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

$organization_primary: #f4811e;
$organization_primary_focus: #ff7700;
$organization_primary_disabled: #f4811e4d;

$organization_program_default: #28156e;

$organization_secondary: #28156e;
$organization_secondary_focus: #1e0f4f;
$organization_secondary_transparent: #28156e9f;
$organization_secundary_border: #7965c4;

$organization_secundary_link: #ffffff;
$organization_secundary_link_hover: #eba66a;

$organization_secundary_button: #f4811e;
$organization_secundary_button_focus: #ff7700;
