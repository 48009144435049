/*

 Custom styles for Telesil organization

            **WARNING**
 Make sure to make changes in custom folder
 Changes made in source will be ignored

*/

.login--form-logo-image {
    width: 185px !important;
}

.login--logo,
.header--logo,
.header-provider--logo {
    max-width: 125px !important;
}